










































import { getModule } from "vuex-module-decorators";
import store from "@/store"
import Vue from "vue"

import eventHub from "@/event-hub";
import { a11yFixIframeTitleAttr } from "@/services/a11y";

import Spinners from "@/components/utilities/Spinners.vue";

import videoEmbedVuexModule from "@/store/vuex-modules/videoEmbed";
const videoEmbedStore = getModule(videoEmbedVuexModule);


export default Vue.extend({
    data() {
        return {
            embedCode: "",
            isLoading: true
        }
    },
    components: { Spinners },
    directives: {
        addiframetitle: {
            bind: function(el: HTMLElement) {
                eventHub.$emit("IframeWithTitleBound", el);
            }
        }
    },
    props: {
        url: {
            type: String,
            required: true
        },
        videoTitle: {
            type: String,
            default: ""
        }
    },
    computed: {
        urlIsUrl(): boolean {
            let returnValue = false;
            const url = this.url || "";

            try {
                new URL(url);
                returnValue = true;
            } catch (error) {
                console.error(error);
            }

            return returnValue;
        },

        getLayoutOptions(): boolean {
            return store.getters.layoutOptions;
        }
    },
    /**
     * Lifecycle
     */
    created() {
        this.handleVideoUrl();
        eventHub.$on("IframeWithTitleBound", this.handleIframeTitleFixes);
    },

    beforeDestroy() {
        eventHub.$off("IframeWithTitleBound", this.handleIframeTitleFixes);
    },

    //methods
    methods: {

        
        handleVideoUrl() {
            const validServices = [
                "youtube.com",
                "youtu.be",
                "vimeo.com",
                "facebook.com"
            ];
            let loadEmbed = false;

            for (let index = 0; index < validServices.length; index++) {
                const service = validServices[index];
                if (this.url && this.url.includes(service)) {
                    loadEmbed = true;
                    break;
                }
            }

            if (loadEmbed) {
                this.getVideoEmbed();
            } else {
                this.isLoading = false;
            }
        },

        getVideoEmbed() {
            const url = this.url;
            let embedService;

            if (
                (url && url.includes("youtube.com")) ||
                (url && url.includes("youtu.be"))
            ) {
                embedService = videoEmbedStore.getYoutubeEmbed;
            } else if (url && url.includes("facebook.com")) {
                embedService = videoEmbedStore.getFacebookEmbed;
            } else {
                embedService = Vue.prototype.MgGetVideoEmbed;
            }

            embedService(url)
                .then((html: string) => {
                    this.embedCode = html;
                })
                .catch((error: Error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                    Vue.nextTick().then(() => {
                        if (window.FB) {
                            window.FB.XFBML.parse();
                        }
                    });
                });
        },

        handleIframeTitleFixes(el: HTMLElement) {
            a11yFixIframeTitleAttr(el);
        }
    }
})
