






































































































































































































































































































import eventHub from "@/event-hub";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";

import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import { formatDistance } from "date-fns";

//vuex module imports
import PostersModule from "@/store/vuex-modules/posters";
import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";

//component imports
import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import PosterCard from "@/components/shared/PosterCard.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import GeneralCommentsComponent from "@/components/shared/GeneralCommentComponent.vue";
import PosterImageUpload from "@/components/sessions/posters/PosterImageUpload.vue";
import UpcomingStreams from "@/components/exhibitor-profile/UpcomingStreams.vue";
import MgImage from "@/components/shared/MgImage.vue";
import FavoriteButton from "@/components/shared/FavoriteButton.vue";
import NotFound from "@/views/NotFound.vue";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";

//interface imports
import {
    PosterObject,
    RelatedPosterObject,
    AttendeeBase,
    AttendeeDataObject
} from "@/types/interfaces";

//vuex store module declarations and initialization
const posterStore = getModule(PostersModule);
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

import {
    sessionHelpSessionIsActive,
    sessionHelpSessionIsOver,
    sessionHelpEarlyAccessMinutes
} from "@/services/session-helpers";


export default Vue.extend ({
    data() {
        return {
            isSessionActive : false,
            isSessionOver : false,
            sessionLabelInterval : 0,
            timeLabel : "",
            imageTimeout : -1,
        
            isLoading : true,
            isLoadingFavorites : true, //loading all favorites
            localeTimezone : "",
            isLoadingPosterImage : false,
        
            isPosterModalOpen : false,
            isPosterNotFound : false,
        }
    },
    components: {
        PosterCard,
        AttendeeCard,
        Spinners,
        GeneralCommentsComponent,
        PosterImageUpload,
        UpcomingStreams,
        MgImage,
        FavoriteButton,
        NotFound,
        PageTitleHeader
    },
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    },
    props: {
        id: {
            type: String
        },
        type: {
            type: String
        }
    // @Prop(String)
    // id!: string;

    // @Prop({ default: "poster" })
    // type!: string;
    },
    computed: {
        sortedAttendeeByLastName(): AttendeeDataObject[] | undefined {
            if (this.posterDetail) {
                return this.posterDetail?.poster_attendees?.sort(
                    (curr: AttendeeBase, nxt: AttendeeBase) => {
                        const curName = curr.lastName
                            ? curr.lastName
                            : curr.name
                            ? curr.name
                            : "";

                        const nextName = nxt.lastName
                            ? nxt.lastName
                            : nxt.name
                            ? nxt.name
                            : "";
                        return curName.localeCompare(nextName);
                    }
                );
            }
        return [];
        },

        userInfo(): Record<string, string> {
            return this.$store.getters.userInfo;
        },
        posterDetail(): PosterObject {
            return posterStore.activePoster;
        },
        canUserEdit(): boolean {
            const isSuperUser = this.$store.getters.isSuperUser;
            const myId = this.userInfo.id;
            const posterOwners = this.posterDetail.attendeeIds || [];

            return isSuperUser || posterOwners.includes(myId);
        },
        posterApiOptions(): Record<string, string> {
            const options = { id: this.id };
            return options;
        },
        myStartTime(): string {
            const returnValue = this.posterDetail.startTime;

            return returnValue;
        },

        sessionDateTime(): Date | null{
            const iso = this.myStartTime;
            return this.myStartTime ? new Date(iso) : null;
        },

        sessionEarlyAccessMinutes(): Date {
            const session = this.posterDetail;
            const returnValue = sessionHelpEarlyAccessMinutes(session);

            return returnValue;
        },

        categoryLabel(): string {
            return this.posterDetail.categories?.length &&
                this.posterDetail.categories.length > 1
                ? this.headerTextWithDefault.categoryPlural
                : this.headerTextWithDefault.categorySingle;
        },

        headerText(): any {
            return this.pageSettings.headerText ? this.pageSettings.headerText : {};
        },

        headerTextWithDefault(): Record<string, string> {
            // We're using typeof so that an empty string can be supplied to omit the header. If the key is not defined, still fall back to default.
            return {
                description:
                    typeof this.headerText.description == "string"
                        ? this.headerText.description
                        : "Poster Description",
                categorySingle:
                    typeof this.headerText.categorySingle == "string"
                        ? this.headerText.categorySingle
                        : "Category",
                categoryPlural:
                    typeof this.headerText.categoryPlural == "string"
                        ? this.headerText.categoryPlural
                        : "Categories",
                comments:
                    typeof this.headerText.comments == "string"
                        ? this.headerText.comments
                        : "Comments",
                related:
                    typeof this.headerText.related == "string"
                        ? this.headerText.related
                        : "Related Posters"
            };
        },

        pageSettings(): Record<string, string> {
            return this.$store.getters.getPageOptions("posters") || {};
        },

        textHeader(): string {
            return this.pageSettings?.textHeader
                ? this.pageSettings?.textHeader
                : "Poster Gallery";
        },

        posterImage(): string {
            const data = this.posterDetail;
            const images = data.images;
            let returnValue = ``;

            if ("string" === typeof images) {
                returnValue = images;
            }

            return returnValue;
        },

        posterImageSrcSet(): string {
            const data = this.posterDetail;
            const set = [];
            let returnValue = "";

            if (data?.images) {
                const urls = data.images;
                const imgSm = urls["800x0"];
                const imgLg = urls["2000x0"];

                if (imgSm) {
                    set.push(`${imgSm} 600w`);
                }

                if (imgLg) {
                    set.push(`${imgLg} 2048w`);
                }

                returnValue = set.join(",");
            }

            return returnValue;
        },

        posterImageToUpload(): string | File {
            return posterStore.posterImage;
        },

        relatedPosters(): RelatedPosterObject[] {
            return posterStore.relatedPosters ? posterStore.relatedPosters : [];
        },

        orderedRelatedPosters(): RelatedPosterObject[] {
            let returnArray: Array<RelatedPosterObject> = [];

            if (this.posterDetail && this.posterDetail.relatedPosters) {
                this.posterDetail.relatedPosters.forEach((posterId: string) => {
                    const thisPoster = this.relatedPosters.find(
                        (related) => related.posterId === posterId
                    );

                    if (thisPoster) {
                        returnArray.push(thisPoster);
                    }
                });
            } else {
                returnArray = this.relatedPosters;
            }

            return returnArray;
        },

        relatedPostersLoading(): boolean {
            return !posterStore.relatedPostersLoaded;
        },

        usePresenterQandA(): boolean {
            return this.pageSettings.usePresenterQandA ? true : false;
        },

        editorFallbacks(): Record<string, string> {
            return {
                image: `/bg/generic-poster-placeholder.jpg`,
                description:
                    "This is where the description entered on the edit form appears, once you enter one.",
                categories:
                    "Categories will appear here when you add them using the edit form.",
                videoUrl: "https://youtu.be/5GPEHSIXdQ0"
            };
        },

        descriptionWithFallback(): string {
            return this.posterDetail.description
                ? this.posterDetail.description
                : this.canUserEdit
                ? this.editorFallbacks.description
                : "";
        },

        videoUrlWithFallback(): string {
            return this.posterDetail.video?.url
                ? this.posterDetail.video?.url
                : this.editorFallbacks.videoUrl;
        },

        hasCategories(): boolean {
            return (
                Array.isArray(this.posterDetail.categories) &&
                this.posterDetail.categories.length > 0
            );
        },

        showPlaceholder(): any {
            return {
                video:
                    this.canUserEdit &&
                    (!this.posterDetail.video || !this.posterDetail.video.url),
                description:
                    this.canUserEdit &&
                    Boolean(
                        !this.posterDetail.description ||
                            this.posterDetail.description.trim() == ""
                    ),
                categories: this.canUserEdit && !this.hasCategories,
                image:
                    this.canUserEdit &&
                    Boolean(this.posterImage == this.editorFallbacks.image)
            };
        }
    },
    watch: {
        // @Watch("id")
        id() {
            this.initializePoster();
        }
    },
    methods: {

        showPosterModal() {
            this.isPosterModalOpen = true;
        },
    
        closePosterModal() {
            this.isPosterModalOpen = false;
            this.initializePoster();
        },
        initializePoster() {
            const postersPromises = [
                this.fetchPoster(),
                attendeeScheduleStore.getFavoritePosters()
            ];
    
            this.isLoading = true;
            this.isLoadingFavorites = true;
    
            Promise.allSettled(postersPromises).then(() => {
                this.isLoading = false;
                this.isLoadingFavorites = false;
    
                this.logPageVisit();
            });
        },
    
        fetchPoster() {
            window.clearInterval(this.sessionLabelInterval);
    
            return new Promise<void>((resolve, reject) => {
                posterStore
                    .getPoster(this.id)
                    .then((response) => {
                        const data = response as PosterObject;
    
                        posterStore.setActivePoster(data);
    
                        if (this.posterDetail.relatedPosters) {
                            posterStore.getRelatedPosters(
                                this.posterDetail.relatedPosters
                            );
                        }
    
                        // check immediately now that data is available
                        this.handleActiveSessionCheck();
    
                        this.sessionLabelInterval = window.setInterval(() => {
                            // check again every second
                            this.handleActiveSessionCheck();
                        }, 1000);
    
                        if (this.posterDetail.relatedPosters) {
                            posterStore.getRelatedPosters(
                                this.posterDetail.relatedPosters
                            );
                        }
    
                        return resolve();
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.isPosterNotFound =
                                error.response.status === 404 ? true : false;
                        }
                        console.error(error);
                        return reject(error);
                    });
            });
        },
    
        handleActiveSessionCheck() {
            const session = this.posterDetail;
            const startDate = this.sessionDateTime;
            if (startDate) {
                this.isSessionActive = sessionHelpSessionIsActive(session);
                this.isSessionOver = sessionHelpSessionIsOver(session);
    
                this.timeLabel = formatDistance(window.MgServerTime, startDate);
            }
        },
    
        goToSession() {
            if (!this.isSessionActive) return;
            this.$router.push(`/meeting/POSTER${this.posterDetail.posterId}`);
        },
    
        getRouteDataForCategory(categoryName: string) {
            return {
                name: "Posters",
                query: {
                    category: categoryName
                }
            };
        },
    
        startPosterImageUpload() {
            this.isLoading = true;
    
            eventHub.$emit("poster-image-upload-started");
    
            this.posterImageUpload()
                .then(() => {
                    eventHub.$emit("poster-image-upload-success");
                })
                .catch(() => {
                    eventHub.$emit("poster-image-upload-error");
                })
                .finally(() => {
                    eventHub.$emit("poster-image-upload-done");
                    this.isLoading = false;
                });
        },
    
        async posterImageUpload() {
            const options = {
                ...this.posterApiOptions,
                image: this.posterImageToUpload
            };
    
            try {
                this.isLoadingPosterImage = true;
                await posterStore.uploadPosterImage(options as any);
                this.imageTimeout = window.setTimeout(() => {
                    /**
                     * We currently don't have a way to "listen" for when image processing is done
                     * so we'll wait x seconds. not a good solution.
                     * this is temp code until we can listen for image processing completion.
                     */
                    this.isLoadingPosterImage = false;
                }, 5000);
            } catch (error) {
                this.isLoadingPosterImage = false;
                throw new Error("Upload failed.");
            }
        },
    
        routeDetails() {
            this.$router.push({
                name: "ModifyPoster",
                params: {
                    id: this.id
                }
            });
        },
    
        logPageVisit() {
            const posterName = this.posterDetail?.title;
            const posterId = this.posterDetail?.posterId;
    
            const logData = {
                type: `PosterView`,
                posterName,
                posterId
            };
    
            this.$store
                .dispatch("appendLogEntry", logData)
                .catch((err) => console.log(err));
        },
        handleBuefyModalFixes(el: HTMLElement) {
            a11yFixBuefyModalAriaAttrs(el);
        }
    },
    created() {
        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
        this.initializePoster();
    },

    beforeDestroy() {
        window.clearInterval(this.sessionLabelInterval);
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
    },

})
