import { Module, VuexModule, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import Store from "../index";

// This module is only for functionality related to the following endpoint:
const endpoint = "videoEmbed";

/**
Note:
------------------
 Youtube
 facebook 
------------------
^ for these services we are using the `videoEmbed` endpoint becasue
client side calls to the oembed api's of the above services are currently not allowed
 */

@Module({
    dynamic: true,
    store: Store,
    name: "videoEmbed",
    namespaced: true
})
export default class VideoEmbedModule extends VuexModule {
    @Action({ rawError: true })
    getYoutubeEmbed(url: string) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    },
                    params: {
                        url: url,
                        service: "youtube"
                    }
                })
                .then((response) => {
                    return resolve(response.data.html);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    getFacebookEmbed(url: string) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    },
                    params: {
                        url: url,
                        service: "facebook"
                    }
                })
                .then((response) => {
                    return resolve(response.data.html);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
