

































import { PosterObject } from "@/types/interfaces";
import Vue from "vue";
import { AttendeeDataObject } from "@/types/interfaces";
import MgImage from "@/components/shared/MgImage.vue";

export default Vue.extend({
    props: {
        data: {
            type: Object,
            required: true,
            default() {
                return {} as PosterObject;
            }
        },
        attendees: {
            type: Array,
            default: () => [] as Array<AttendeeDataObject>
        },
        id: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        speakerInfoString: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            isLoading: true
        };
    },

    filters: {
        maxCharacter(value: string) {
            return value.slice(0, 80);
        }
    },
    components: {
        MgImage
    },
    computed: {
        pageOptions(): Record<string, any> {
            return this.$store.getters.getPageOptions("posters") || {};
        },

        posterImage(): string {
            const data = this.data;
            const img = data.images;
            let returnValue = ``;

            if ("string" === typeof img) {
                returnValue = img;
            } else if (this.pageOptions.posterFallBackImage) {
                returnValue = `/fallback-images/${this.pageOptions.posterFallBackImage}`;
            }

            return returnValue;
        },

        posterImageSrcSet(): string {
            const data = this.data;
            const set = [];
            let returnValue = ``;

            if (data?.images) {
                const urls = data.images;
                const imgSm = urls["800x0"];
                const imgLg = urls["2000x0"];

                if (imgSm) {
                    set.push(`${imgSm} 600w`);
                }

                if (imgLg) {
                    set.push(`${imgLg} 2048w`);
                }

                returnValue = set.join(",");
            }

            return returnValue;
        },

        sortedAttendee(): Array<AttendeeDataObject> | unknown {
            if (this.attendees.length < 2) {
                return this.attendees;
            } else {
                const toSort = this.attendees as Array<AttendeeDataObject>;

                return toSort.sort(
                    (item: AttendeeDataObject, nxt: AttendeeDataObject) => {
                        return item.lastName && nxt.lastName
                            ? item.lastName.localeCompare(nxt.lastName)
                            : 0;
                    }
                );
            }
        },

        routeDetails(): Record<string, string | Record<string, string>> {
            return {
                name: "PosterDetails",
                params: {
                    id: this.id
                }
            };
        }
    }
});
