
























































































import Vue from "vue";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import { GeneralCommentsObjectContent } from "@/types/interfaces";
import { getModule } from "vuex-module-decorators";

import GeneralCommentsModule from "@/store/vuex-modules/GeneralComments";

const commentsStore = getModule(GeneralCommentsModule);

export default Vue.extend ({
    props: {
        commentId: {
            type: String,
            default: ""
        },
        accessor: {
            type: String,
            default: ""
        },
        heading: {
            type: String,
            default: "Comments"
        }
    },
    data() {
        return {
            commentsInterval: -1,
            messageToComment: "",
            comments:[] as Array<GeneralCommentsObjectContent>,
            commentIsPosting:false,
            counter:0,
        
            objParams:{
                id: this.commentId,
                type: this.accessor,
                loadFrom: ""
            }
        }
    },
    computed: {
        /**
         * Computed
         */
        userId(): string {
            return this.$store.getters.myBespeakeId;
        },
    
        isoLastCommentLoaded(): string {
            const comments = this.comments;
            let returnValue = "";
            let lastItem;
    
            if (Array.isArray(comments)) {
                lastItem = comments[comments.length - 1];
            }
    
            if (lastItem && lastItem?.createdTime) {
                returnValue = lastItem.createdTime;
            }
    
            return returnValue;
        }
    },
    methods: {

        /**
         * Methods
         */
        isOwnComment(comment: GeneralCommentsObjectContent) {
            return Boolean(this.userId === comment.attendeeId);
        },
    
        clearCommentsInterval() {
            window.clearInterval(this.commentsInterval);
        },
    
        loadComments() {
            this.clearCommentsInterval();
    
            this.objParams.loadFrom = this.isoLastCommentLoaded;
    
            const params = this.objParams;
            commentsStore
                .getComments(params)
                .then((res) => {
                    const x = res as Array<GeneralCommentsObjectContent>;
                    const newComments = [...this.comments, ...x];
    
                    this.comments = uniqWith(newComments, isEqual);
                    this.commentsInterval = window.setInterval(
                        this.loadComments,
                        5000
                    );
                })
                .catch((error) => {
                    console.error(error);
    
                    /**
                     * Fetch may fail for any number of reasons,
                     * we should continue fetch attempts.
                     */
                    this.commentsInterval = window.setInterval(
                        this.loadComments,
                        5000
                    );
                });
        },
    
        addComment() {
            if (this.commentIsPosting) return;
    
            const commentObj = {
                relatedObjectId: this.commentId,
                relatedObjectType: this.accessor,
                text: this.messageToComment
            };
    
            this.clearCommentsInterval();
    
            this.commentIsPosting = true;
            commentsStore
                .postComment(commentObj)
                .then(() => {
                    this.loadComments();
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.commentIsPosting = false;
                    this.messageToComment = "";
    
                    // Only want to scrollchat after submitting a comment
                    this.scrollChat();
                });
        },
    
        scrollChat() {
            this.$nextTick(() => {
                const chatScroll: any = this.$refs["chat-scroll"];
                // scroll to bottom of chat
                if (chatScroll) {
                    chatScroll.scrollTo(null, chatScroll.scrollHeight);
                }
            });
        }
    },
    /**
     * Lifecycle
     */
    created() {
        this.loadComments();
    },

    beforeDestroy() {
        this.clearCommentsInterval();
    }
})
