var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-w-md mb-32",staticStyle:{"height":"550px"}},[_c('div',{staticClass:"comment-section w-full relative bg-white border border-dividers h-full flex justify-center items-center"},[(0 === _vm.comments.length)?_c('div',{staticClass:"w-full h-64 flex justify-center items-center"},[_c('div',{staticClass:"flex justify-center items-center"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"aria-hidden":"true","icon":"comment"}}),_c('div',[_vm._v("Be the first to add a comment")])],1)]):_vm._e(),_c('div',{ref:"chat-scroll",staticClass:"comment-section-inner absolute top-0 left-0 h-full w-full fix-scroll-to-bottom overflow-y-auto overscroll-contain",attrs:{"tabindex":"0"}},[_vm._l((_vm.comments),function(comment,index){return _c('div',{key:index,staticClass:"sc-VigVT chat__message-wrapper px-4 my-6"},[_c('div',{staticClass:"sc-jTzLTM overflow-hidden djefvl flex text-sm justify-between"},[_c('div',{staticClass:"sc-fjdhpX chat_message-from font-bold"},[(comment.attendeeId)?_c('router-link',{attrs:{"to":("/attendees/attendee-profile/" + (comment.attendeeId))}},[_vm._v(_vm._s(comment.attendeeName))]):_c('span',[_vm._v(_vm._s(comment.attendeeName))])],1),_c('div',{staticClass:"sc-jzJRlG chat_message-time"},[_vm._v(" "+_vm._s(_vm.MgFormatISODateTime( comment.createdTime, "date-time" ))+" ")])]),_c('div',{staticClass:"sc-cSHVUG chat__chat-message mt-2",class:{
                        'chat__chat-message--hightlight': comment.highlight,
                        'chat__chat-message--outgoing': _vm.isOwnComment(
                            comment
                        ),
                        'chat__chat-message--incoming': !_vm.isOwnComment(
                            comment
                        )
                    }},[_c('div',{staticClass:"chat__chat-message-inner break-links block",domProps:{"innerHTML":_vm._s(comment.text)}})])])}),_c('div',{staticClass:"fix-scroll-to-bottom__anchor"})],2)]),_c('form',{staticClass:"py-4 block",on:{"submit":function($event){$event.preventDefault();return _vm.addComment.apply(null, arguments)}}},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageToComment),expression:"messageToComment"}],staticClass:"input is-info",attrs:{"disabled":_vm.commentIsPosting,"type":"text","placeholder":"Enter comments here.."},domProps:{"value":(_vm.messageToComment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.messageToComment=$event.target.value}}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }