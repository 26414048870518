import Vue from "vue";
import { getApiClient } from "@/services/api";
import { Action, Module, VuexModule } from "vuex-module-decorators";
import Store from "../index";
import linkifyHtml from "linkifyjs/html";
import { config } from "vuex-module-decorators";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

// This module is only for functionality related to the following endpoint:
const endpoint = "generalComments";

export interface ObjectParams {
    id: string;
    type: string;
    loadFrom: string;
}

@Module({
    dynamic: true,
    store: Store,
    name: "CommentsModule",
    namespaced: true
})
export default class CommentsModule extends VuexModule {
    // Comments should be stored in component not here.

    @Action({})
    getComments(obj: ObjectParams) {
        const commentId = obj.id;
        const objectType = obj.type;
        const loadFrom = obj.loadFrom;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(
                    `${endpoint}/${objectType}/${commentId}?after=${loadFrom}`,
                    {
                        headers: {
                            Authorization: `bearer ${token}`
                        }
                    }
                )
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    @Action({})
    postComment(msgObj: any) {
        const commentId: string = msgObj.relatedObjectId;
        const objectType: string = msgObj.relatedObjectType;
        const token = this.context.rootGetters.idToken;

        let text = linkifyHtml(msgObj.text, {
            attributes: {
                rel: "noopener noreferrer"
            }
        });

        text = Vue.prototype.MgSanitize(text);

        return new Promise((resolve, reject) => {
            getApiClient()
                .post(
                    `${endpoint}/${objectType}/${commentId}`,
                    { text },
                    {
                        headers: {
                            Authorization: `bearer ${token}`
                        }
                    }
                )
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
}
